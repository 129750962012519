import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Post from "../components/post"
import SEO from '../components/seo'

const BlogPage = ( {data} ) => {


	return (
		<Layout>
    <SEO title="Posts | poetry.me" description="Musings and related works" />
		 <ul className="breadcrumb">
        <li><Link to="/">Home</Link></li>
        <li>Blog</li>
        </ul>
        <div className="post-list">
		{data.allMarkdownRemark.edges.map( (p,i) => <Post key={i} post={p} />)}
		</div>
		</Layout>
	);
}

export const query = graphql`
query BlogQuery {
  allMarkdownRemark(filter: {fields: {slug: {glob: "*/blog/*"}}}, sort: {order: DESC, fields: frontmatter___date}) {
    edges {
      node {
        fields {
          slug
        }
        id
        excerpt(pruneLength: 250)
        frontmatter {
          date(formatString: "Y-MM-DD")
          title
          icon {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
          }
        }
      }
    }
  }
}`

export default BlogPage
